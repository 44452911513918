import React from 'react'
import { Table } from "antd"
import { Col, Container, Row } from 'react-bootstrap'
import { useQuery } from "react-query"

import { apiAuthorization } from '../../services/api'

import Box from '../../components/Card'
import Main from '../../components/Main'
import Navigation from '../../components/Navigation'

export default function Users() {

  const { data: users } = useQuery(
    `users`,
    async () => {
      const response = await apiAuthorization().get(`/usuarios`);
      return response.data;
    },
    {
      staleTime: 1000 * 60, // 1 minute
    }
  );

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Permissão",
      dataIndex: "permission",
      key: "permission",
    },
  ];

  return (
      <Main>
        <Container>
          <Navigation active={'Lista de Usuários'}/>
          <Row>
            <Table columns={columns} dataSource={users} />
            {/* {users?.map( (user) => {
              return(
                <div>{user.name}</div>
              )
            })} */}
          </Row>
        </Container>
      </Main>
  );
}
