import { Col, Container, Row } from "react-bootstrap";

import Main from "../../components/Main";
import Navigation from "../../components/Navigation";
import FormNewQuiz from "../../components/FormNewQuiz";

export default function NewQuiz() {
  return (
    <Main>
      <Container>
        <Navigation active={"Novo Questionário"} />
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <FormNewQuiz />
          </Col>
        </Row>
      </Container>
    </Main>
  );
}
