import React, { useState, useEffect, createContext } from 'react'
import { useHistory } from 'react-router-dom';

import { apiAuthorization } from "../services/api";

export const BMCContext = createContext({});

export const BMCProvider = (props) => {
    const [ profile, setProfile ] = useState({});

    // useEffect(() => {        
    //     apiAuthorization()
    //        .get("/tenants")
    //        .then((resp) => {
    //            if (resp.status === 200) {
    //                 setTenants(resp.data);
    //                 console.log(resp.data);
    //            } else {
    //            }
    //        }).catch((error) => {
    //             console.log(error);
    //        });
    // }, []);

    return (
        <BMCContext.Provider
            value={{
                profile,
                setProfile,
            }}
        >
            {props.children}
        </BMCContext.Provider>
    )
}
