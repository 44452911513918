import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";

import { apiAuthorization } from "../../services/api";

import Box from "../../components/Card";
import Main from "../../components/Main";
import Navigation from "../../components/Navigation";
import { LightBox } from "../../components/Modal";

export default function Quizzes() {
  // const { quizzes, setQuizzes } = useState();

  const { data:quizzes, isLoading } = useQuery(
    `quizzes`,
    async () => {
      const response = await apiAuthorization().get(`/questionarios`);
      return response.data;
    },
    {
      staleTime: 1000 * 60, // 1 minute
    }
  );

  if (isLoading) return "Loading...";


  async function handleDelete(value, e) {
    const deleteQuiz = await apiAuthorization()
      .delete(`/questionarios/` + value)
      .then((resp) => console.log(resp.data.message));

      // const actualQuizzes = quizzes.filter(item => item.id !== value);  
      // console.log(actualQuizzes);

  }

  return (
    <Main>
      <Container>
        <Navigation active={"Lista de Questionários"} />
        {/* <LightBox/> */}
        <Row>
          {quizzes?.map((quiz) => {
            return (
              <Col key={quiz.id} lg={3}>
                <Box
                  title={quiz.title}
                  subtitle={quiz.publish_in}
                  link="Ver"
                  onClick={(e) => {
                    handleDelete(quiz.id, e);
                  }}
                  linkTwo="Excluir"
                  url={`/questionarios/${quiz.id}`}
                >
                  Descrição
                </Box>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Main>
  );
}
