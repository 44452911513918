import { Container, Row, Col } from 'react-bootstrap'

import FormLogin from '../../components/FormLogin'

export default function Login() {
  return (
    <Container>
      <Row className='mt-10'>
        <Col md={{ span: 6, offset: 3 }}>
         <FormLogin/>
        </Col>
      </Row>
    </Container>
  );
}
