import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useQuery } from "react-query"

import { apiAuthorization } from '../../services/api'

import Main from '../../components/Main'
import Navigation from '../../components/Navigation'

export default function Cnaes() {

  const { data: cnaes } = useQuery(
    `cnaes`,
    async () => {
      const response = await apiAuthorization().get(`/cnaes`);
      return response.data;
    },
    {
      staleTime: 1000 * 60, // 1 minute
    }
  );

  return (
    <div>
      <Main>
        <Container>
          <Navigation active={'Lista de Questionários'}/>
          <Row>
            {cnaes?.map( (cnae) => {
              return(
                // <Col key={quiz.id} lg={3}>
                //   <Box title={quiz.title} subtitle={quiz.publish_in} link='Ver' >Descrição</Box>
                // </Col>
                cnae.Id_Sequencial_Subclasse
              )
            })}
          </Row>
        </Container>
      </Main>
    </div>
  );
}
