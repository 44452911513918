import React, { useState } from "react";
import { Table } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { apiAuthorization } from "../../services/api";

import Main from "../../components/Main";
import Navigation from "../../components/Navigation";

export default function Quiz() {
  const { id } = useParams();

  const { data: quiz } = useQuery(
    `quiz_${id}`,
    async () => {
      const response = await apiAuthorization().get(`/questionarios/${id}`);
      return response.data;
    },
    {
      staleTime: 1000 * 60, // 1 minute
    }
  );

  const columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Pontos",
      dataIndex: "points",
      key: "points",
    },
  ];

  return (
    <Main>
      <Container>
        <Navigation active={"Questionário " + id} />
        <Row>
          <div>Título{quiz?.title}</div>
          <div>Publicado em {quiz?.publish_in}</div>
          <div>Pontos {quiz?.points}</div>
          <div>Flag {quiz?.flag}</div>
          <div>Observação {quiz?.obs}</div>
        </Row>
        <Row>
        <Table columns={columns} dataSource={quiz?.questions} />
        </Row>
      </Container>
    </Main>
  );
}
