import React, { useEffect, useState, useContext } from "react"
import { Route, Redirect } from "react-router-dom"

import { apiAuthorization } from "./api"
import { LoadingPage } from "../pages/LoadingPage"

import { BMCContext } from '../providers/BMCProvider'

const Auth = ({ component: Component, ...rest }) => {
    const [isLogged, setIsLogged] = useState(null);
    const { setProfile } = useContext(BMCContext);

    useEffect(() => {        
         apiAuthorization()
            .get("/me")
            .then((resp) => {
              if (resp.status === 200) {
                const { data } = resp;
                setProfile(data.user);
                  setIsLogged(true);
                } else {
                    setIsLogged(false);
                }
            }).catch((error) => {
                setIsLogged(false);
            });
    }, [setProfile]);

    if (isLogged == null) {
        return (
          <LoadingPage/>
        )
    }

    return (
        <Route
          {...rest}
          render={(props) =>
            isLogged ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }
        />
      );
};

export default Auth;

