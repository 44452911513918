import { Route, BrowserRouter as Routers, Switch } from "react-router-dom";

import Auth from './services/auth';

import Login from './pages/Login'

import Dashboard from './pages/Dashboard'
import Quizzes from './pages/Quizzes'
import Quiz from './pages/ShowQuiz'
import NewQuiz from './pages/NewQuiz'
import Cnaes from './pages/Cnaes'
import Users from "./pages/Users";

const Routes = () => {
    
  return  (
      <Routers>
            <Switch>
                <Route component={ Login } path="/login" exact />

                <Auth component={ Dashboard } path="/" exact />
                <Auth component={ Quizzes } path="/questionarios" exact />
                <Auth component={ NewQuiz } path="/questionarios/novo" exact />
                <Auth component={ Quiz } path="/questionarios/:id" exact />
                <Auth component={ Cnaes } path="/cnaes" exact />
                <Auth component={ Users } path="/usuarios" exact />
            </Switch>
      </Routers>
  )
}
export default Routes
