
import { Container } from "react-bootstrap"
import Main from "../../components/Main"

export default function Dashboard(){

  return (
    <div>
      <Main>
        <Container>
        Teste
        </Container>
      </Main>
    </div>
  )
}