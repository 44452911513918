import React, { useContext } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import Brand from "../../assets/imgs/brand.svg";

import { BMCContext } from "../../providers/BMCProvider";

export default function Header() {
  const { profile } = useContext(BMCContext);
  return (
    <Navbar bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand>
          <Link to="/">
            <img src={Brand} alt="Logotipo branco da Brasil Mais Conforme com fundo transparente" />
          </Link>
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link>
            <Link to="/">Painel</Link>
          </Nav.Link>

          <NavDropdown title="Questionários" id="basic-nav-dropdown">
            <NavDropdown.Item>
              <Link to="/questionarios">Todos</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/questionarios/novo">Criar novo</Link>
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link>
            <Link to="/cnaes">Cnaes</Link>
          </Nav.Link>

          <Nav.Link>
            <Link to="/usuarios">Usuários</Link>
          </Nav.Link>
        </Nav>

        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>{profile?.name}</Navbar.Text>
          <Navbar.Text>
            <div href="#login">Sair</div>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
